import React, { useState, useEffect } from "react";
import http from '../_api/http';
import { Button, Modal } from 'react-bootstrap'

const ScrollEvent = () => {
    const [Events, setEvents] = useState([]);
    const [scrollClass, setscrollClass] = useState("scroll-text");
    const [ScrollView, setScrollView] = useState("none");
    const [show, setModalShow] = useState(false);
    const [imgSrc, setimgSrc] = useState("");
    const [adminButton, setadminButton] = useState("none");
    const PUBLIC_URL = process.env.REACT_APP_APIURL;

    const scrollCss = {
        background: '#f77f17',
        color: 'white',
        height: '30px',
        marginTop: '13px',
        marginBottom: '5px',
        display: ScrollView
    }

    const popupImage = {
        height: '400px',
        width: '95%',
        position: 'absolute'
    }
    const adminButtonStyle = {
        display: adminButton,
        position: "absolute",
        bottom: "0px",
        marginBottom: "10px",
        marginLeft: "35%",
        zIndex:"999"
    }

    useEffect(() => {

        http.get('api/event/getLatestEvent').then((res) => {
            setEvents(res.data.event);
            if (res.data.event.length > 0) {
                setScrollView("block");
            }
        });

        http.get('api/welcome/getLatestPopup').then((res) => {
            if (res.data.event.length > 0) {
                var data = res.data.event;
                setModalShow(true);
                setimgSrc(PUBLIC_URL + "uploadimage/" + data[0].imagePath);
                setadminButton(data[0].admissionButton == "Y" ? "block" : "none");
            }
        });
    }, []);

    const handleClose = () => {
        setModalShow(false);
    }

    return (
        <section className="col-md-12" style={scrollCss}>
            <div id="scrollRop">
                {
                    <label className={scrollClass} onMouseEnter={() => {
                        setscrollClass("scroll-text animpaused");
                    }}
                        onMouseLeave={() => {
                            setscrollClass("scroll-text");
                        }}>
                        {Events.map((item, i) =>
                            <span key={i} style={{ marginRight: "5px" }}>
                                {item.description} on {item.eventDate};
                            </span>
                        )
                        }
                    </label>
                }
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <img src={imgSrc} style={popupImage} ></img>
                    <a href='/admission' className="btn btn-info" style={adminButtonStyle}>Admission</a>
                </Modal.Header>
                <Modal.Body style={{ "overflow": "auto", "height": "400px" }}>
                </Modal.Body>
            </Modal>

        </section>
    )
}
export default ScrollEvent;
