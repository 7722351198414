import React, { useState, useEffect } from 'react'
import http from '../_api/http'

const SchoolVideo = () => {

  const [loading, setLoading] = useState(true)

  const [youtube, setYoutube] = useState([])

  useEffect(() => {
    http.get('api/youtube/ApiYoutubeList').then(res => {
      if (res.data.status === 200) {
        setYoutube(res.data.youtube)
        setLoading(false)
      }
    })
  }, [])

  return (
    <section className='blog-two' style={{marginTop:"60px",marginBottom:"300px"}}>
      <div className='container'>
        <div className='block-title text-center'>
          <h1 className='block-title__title fontSize'>Video Gallary</h1>
        </div>

        <div className='blog-two__carousel'>
          <div className='row'>
            {loading ? (
              <h4>View YouTube Video Loading...</h4>
            ) : (
              <>
                {youtube.map((item, i) => (
                  <div className='col-md-4' key={i}>
                    <div className='card'>
                      <div className='card-block p-3'>
                        <div className='embed-responsive embed-responsive-16by9'>
                          <iframe
                            className='embed-responsive-item'
                            src={
                              `https://www.youtube.com/embed/` + item.linkname
                            }
                            allowFullScreen
                          />

                        </div>
                        <label className="videoLabel">{item.name}</label>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SchoolVideo;
